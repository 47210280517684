:root {
  color-scheme: light dark;

  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";

  --font-size-small: clamp(0.9rem, 0.41vw + 0.8rem, 1.13rem);
  --font-size-medium: clamp(1.13rem, 0.68vw + 0.95rem, 1.5rem);
  --font-size-large: clamp(1.41rem, 1.08vw + 1.14rem, 2rem);
  --font-size-xlarge: clamp(1.76rem, 1.65vw + 1.35rem, 2.67rem);
  --font-size-xxlarge: clamp(2.2rem, 2.46vw + 1.58rem, 3.55rem);
  /*
  https://www.fluid-type-scale.com/calculate?minFontSize=18&minWidth=400&minRatio=1.25&maxFontSize=24&maxWidth=1280&maxRatio=1.333&steps=small%2Cmedium%2Clarge%2Cxlarge%2Cxxlarge&baseStep=medium&prefix=font-size&decimals=2&useRems=on&previewFont=Inter
  */

  --gray-100: #f3f4f6;
  --gray-900: #111827;

  --color: var(--gray-900, #000);
  --bg: var(--gray-100, #fff);

  --primary: #000;
  --primary-border: #ccc;
  --primary-bg: #ccc;

  --secondary: #666;
  --secondary-border: #ccc;
  --secondary-bg: transparent;

  --header-bg: #fff;
  --article-bg: #fff;
  --footer-bg: #fff;
  --aside-bg: #fff;
}
/*
Task farger: 
Limegrønn: #97d700
Mørk blå/svart: #2f373e
*/

html {
  --primaryH: 78;
  --primaryS: 100%;
  --primaryL: 42%;
  --primary-bg: #efefef;
  --secondaryH: 208;
  --secondaryS: 14%;
  --secondaryL: 21%;
  --secondary-bg: #adc19e;

  /*
  --primary-bg: #fff;
  --secondary-bg: #eee;

  --main-bg-color: #efefef;
  --main-color: #303030;

  --primary-color: #00f;
  --secondary-color: #ccc;
  */

  --correct-color: green;
  --incorrect-color: red;

  --border-radius-small: 2px;
  --border-radius-medium: 5px;
  --border-radius-large: 10px;
  --border-radius-full: 9999px;

  /* Status colors */
  --error: #d00;
  --warning: #ec0;
  --info: #369;
  --correct: #080;
}

html[data-theme="dark"] {
  --primary-bg: #000;
}

@media (prefers-color-scheme: dark) {
  html {
    --primary-bg: #000;
  }
  img {
    filter: brightness(0.8) contrast(1.2);
  }
}
